import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import ArticlesComponent from "../components/articles"

import "../assets/css/main.css"

const IndexPage = () => (
  <Layout title="首页">
    <StaticQuery
      query={graphql`
        query {
          allStrapiArticle {
            edges {
              node {
                id
                title
                strapi_id
              }
            }
          }
        }
      `}
      render={data => (
        <ArticlesComponent articles={data.allStrapiArticle.edges} />
      )}
    />
  </Layout>
)
export const Head = () => <title>首页</title>
export default IndexPage
