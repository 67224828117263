import React from "react"
import { Link } from "gatsby"

const Card = ({ article }) => {
  return (
    <Link to={`/article/${article.node.strapi_id}`} className="uk-link-reset">
      <div className="uk-card uk-card-muted">
        <div className="uk-card-media-top"></div>
        <div className="uk-card-body">
          <p id="title" className="uk-text-large">
            {article.node.title}
          </p>
        </div>
      </div>
    </Link>
  )
}

export default Card
